@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;  
  overscroll-behavior: none;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* overflow: hidden; */
  /* background-color: #f2f2f2; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}